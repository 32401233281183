<template>
  <div>
    <section class="con1">
      <div class="intro">
        <object id="main_svg" class="intro_img" data="img/main.svg" type="image/svg+xml" aria-label="메인이미지"></object>
        <div class="scroll">
          <p>SCROLL DOWN</p>
          <img src="img/scroll.png" alt="">
          <img src="img/scroll.png" alt="">
        </div>
      </div>
    </section>
    <section class="con2">
      <div class="line">
      </div>
      <div class="con2_left">
        <div class="con2_line"> </div>
        <h2>MAP MIND</h2>
        <p>맵마인드는 지속적인 발전을 통해 혁신적인 <strong>솔루션</strong>을<br> 
            제공함으로써 사회와 도시가 직면한 문제를 당사의 기술을 통해 해결하는데 일조하고자 합니다.<br><br>
            <strong>GIS</strong>와 <strong>플랫폼</strong>이라는 도구를 통해 사회에 직면한 문제를<br> 
            이해하고 사람 중심의 혁신적 디자인으로 다양한 조직을 위한 통합 솔루션을 제공합니다. 
        </p>
        <router-link to="/sub1_a" class="infoBtn" tag="div"><div></div><strong>More Info</strong> <i class="fas fa-chevron-right"></i></router-link>
      </div>
      <div class="con2_right">
        <div class="con2_a">
          <span><strong>06</strong>년</span>
          <p>SINCE 2017.09</p>
        </div>
        <div class="con2_b">
          <span><strong>0a</strong>+</span>
          <p>PROJECT</p>
        </div>
        <div class="con2_c">
          <span><strong>08</strong>+</span>
          <p>PARTNERS</p>
        </div>
        <div class="con2_d">
          <span><strong>10</strong>명</span>
          <p>EMPLOYEES</p>
        </div>
      </div>
    </section>
    <section class="con3">
      <div class="line_top"></div>
      <div class="line_bottom"></div>
      <div class="con3_txt">
        <div class="con3_left1 on">
          <h2>Platform</h2>
          <p>독립적으로 구현되어 제공하는<br>다양한 서비스의 통합을 위해 동일한<br>Look & Feel을 제공할 수 있도록 공통모듈과 Design을 제공하는 Framework</p>
          <router-link to="/sub2_a" class="infoBtn" tag="div"><div></div><strong>More Info</strong><i class="fas fa-chevron-right"></i></router-link>
          <img src="/img/con3_platform.png" alt="">
        </div>
        <div class="con3_left2">
          <h2>Dashboard</h2>
          <p>다양한 서버 및 데이터의 연계를 통해<br>Headquarter에서 모니터링 및 관제기능을<br>제공하는 통합모니터링 시스템</p>
          <router-link to="/sub2_b" class="infoBtn" tag="div"><div></div><strong>More Info</strong><i class="fas fa-chevron-right"></i></router-link>
          <img src="/img/con3_dashboard.png" alt="">
        </div>
        <div class="con3_left3">
          <h2>GIS</h2>
          <p>Open source 기반의 Framework을 활용<br>하여 2D & 3D 기반의 공간정보 서비스를<br> 제공하는 자체 공간 정보 솔루션</p>
          <router-link to="/sub2_c" class="infoBtn" tag="div"><div></div><strong>More Info</strong><i class="fas fa-chevron-right"></i></router-link>
          <img src="/img/con3_gis.png" alt="">
        </div>
        <div class="con3_left4">
          <h2>Mobile</h2>
          <p>안드로이드 기반의 모바일 서비스로<br>학원관리 및 현장 설비 관리 등<br>다양한 모바일 솔루션 제공</p>
          <router-link to="/sub2_d" class="infoBtn" tag="div"><div></div><strong>More Info</strong><i class="fas fa-chevron-right"></i></router-link>
          <img src="/img/con3_mobile.png" alt="">
        </div>
        <div class="con3_left5">
          <h2>OPEN GIS</h2>
          <p>당사가 보유한 GIS 기술에 공공 data를<br> 융합하여 시민들에게 유용한 정보를<br> 제공하는 GIS 기반의 open 사이트</p>
          <router-link to="/sub2_e" class="infoBtn" tag="div"><div></div><strong>More Info</strong><i class="fas fa-chevron-right"></i></router-link>
          <img src="/img/con3_opengis.png" alt="">
        </div>
      </div>
      <div class="iconbtn">
        <div class="i1"><img src="/img/left_icon.png" alt="" class="leftbtn"></div>
        <div class="i2"><img src="/img/right_icon.png" alt="" class="rightbtn"></div>
      </div>
      <div id="con3_box" data-idx="0" class="con3_box">
        <div class="con3_right">
          <div class="service_a"><img src="/img/platform.png" alt="" class="on"><p>PlatForm</p></div>
          <div class="service_a"><img src="/img/dashboard.png" alt=""><p>Dashboard</p></div>
          <div class="service_b"><img src="/img/gis.png" alt=""><p>GIS</p></div>
          <div class="service_c"><img src="/img/mobile.png" alt=""><p>MOBILE</p></div>
          <div class="service_d"><img src="/img/opengis.png" alt=""><p>OPEN GIS</p></div>
        </div>
      </div> 
    </section>
    <section class="con4">
      <div class="con4_box">
        <div class="con4_title"><h2>NOTICE</h2></div>
        <router-link to="/sub3" class="notice_btn" tag="div">
          <p> </p>
          <i class="fas fa-plus"></i>
          <p> </p>
          <p> </p>
        </router-link>
        <div id="mainNoticeWrap" class="con4_box2"></div>
      </div>
    </section>
    <section class="con5">
      <div class="con5_txt">
        <h2>We work with <br><strong>Partners</strong></h2>
        <p><i class="far fa-handshake"></i>맵마인드와 함께 프로젝트를 수행한 고객사 현황입니다.</p>
        <div class="project">
          <div class="pro1">
            <h3>▶ 기관명 : 서울특별시</h3>
            <p><strong>▷ PROJECT : 마곡지구 에너지 통합 플랫폼 구축</strong><br>- 에너지 데이터를 기반으로 통합 모니터링 Dashboard 및 운영 포털 구축</p>
          </div>
          <div class="pro2">
            <h3>▶ 기관명 : 한국전력공사</h3>
            <p><strong>▷ PROJECT : 가족친화 포털 리뉴얼</strong><br>- 친근함 부각시키는 디자인을 기반으로 사이트 UI/UX 리뉴얼</p>
          </div>
          <div class="pro3">
            <h3>▶ 기관명 : 한국전력연구원</h3>
            <p><strong>▷ PROJECT : 해외 수출형 MDMS, 나주 / 세종 스마트 시티</strong><br>- AMI Data 기반의 MDMS 서비스 구현<br>- 스마트 시티 에너지 플랫폼을 위한 Dashboard, 포털, GIS 구현</p>
          </div>
          <div class="pro4">
            <h3>▶ 기관명 : 한국전력KDN</h3>
            <p><strong>▷ PROJECT : ADMS 지형도 기반 계통도 변환 시스템 구축</strong><br>- ADMS를 위한 전력설비 GIS 변환 및 Data 구축<br>- 정전 관리 O & M 서비스 구현</p>
          </div>
          <div class="pro5">
            <h3>▶ 기관명 : KRISO</h3>
            <p><strong>▷ PROJECT : 해군 지형정보 GIS DB 구축</strong><br>- 다양한 군사용 공간 데이터 분석 및 분석 툴 구현<br>- GIS 데이터 자동구축 솔루션 구현</p>
          </div>
          <div class="pro6">
            <h3>▶ 기관명 : ATOM</h3>
            <p><strong>▷ PROJECT : 해외 수출용 GIS 모듈 구현</strong><br>- MDMS를 위한 다양한 Schema의 해외 공간 Data를 통합구축 솔루션 및 Web GIS 서비스 구현</p>
          </div>
          <div class="pro7">
            <h3>▶ 기관명 : MOBIGEN</h3>
            <p><strong>▷ PROJECT : Open API 서비스 사이트 디자인</strong><br>- 대시민용 서비스를 제공하는 Open API 개발자 사이트 UI/UX 구현</p>
          </div>
          <div class="pro8">
            <h3>▶ 기관명 : EGIS</h3>
            <p><strong>▷ PROJECT : 스마트시티 에너지 플랫폼 GIS 구축</strong><br>- 스마트 시티 에너지 플랫폼의 GIS DB 구축 및 Dashboard, GIS 서비스 구현</p>
          </div>
        </div>
        <router-link to="/sub4" class="infoBtn" tag="div">WORK WITH US <div class="btnline"> </div><i class="fas fa-chevron-right"></i></router-link>
      </div>
      <div class="company_name">
        <div class="boxa"><img src="/img/con5_a.png" alt=""></div>
        <div class="boxa"><img src="/img/con5_b.png" alt=""></div>
        <div class="boxa"><img src="/img/con5_c.png" alt=""></div>
        <div class="boxa"><img src="/img/con5_d.png" alt=""></div>
        <div class="boxa"><img src="/img/con5_e.png" alt=""></div>
        <div class="boxa"><img src="/img/con5_f.png" alt=""></div>
        <div class="boxa"><img src="/img/con5_g.png" alt=""></div>
        <div class="boxa"><img src="/img/con5_h.png" alt=""></div>
        <div class="boxa"><img src="/img/con5_i.png" alt=""></div>
      </div>
      <div class="company_name">
        <div class="boxb">Smartcity</div>
        <div class="boxb">Design</div>
        <div class="boxb">MDMS,<br>Smartcity</div>
        <div class="boxb">ADMS</div>
        <div class="boxb">GIS</div>
        <div class="boxb">GIS</div>
        <div class="boxb">Design</div>
        <div class="boxb">GIS</div>
      </div>
    </section>
  </div>
</template>
<script>
let svgInterval = null;
let svgAnimate = function(id){
  let duration = Math.floor( ( Math.random() * ( 2000 - 500 )  ) + 500 );
  $("#main_svg").contents().find("#" + id).css("transform-origin", "center center");
  $("#main_svg").contents().find("#" + id).css("transition-duration", duration + "ms");
  $("#main_svg").contents().find("#" + id).css("transform", "scale(1.1)");
  setTimeout(function(){
    $("#main_svg").contents().find("#" + id).css("transform", "scale(1)");
  }, duration);
  setTimeout(function(){
    svgAnimate(id);
  }, duration * 2);
}
let svgAnimateHandle = function(){
  let txtArray = ["db_txt", "gis_txt", "pf_txt", "mobil_txt", "open_gis_txt"];
  for(let i=0 ; i<txtArray.length ; i++){
    svgAnimate(txtArray[i]);
  }
}
clearInterval(svgInterval);
svgInterval = setInterval(function(){
  if($("#main_svg").contents().find("#db_txt").length > 0){
    svgAnimateHandle();
    clearInterval(svgInterval);
  }
}, 100);

let navHoverIdx = 0;
let navInterval = null;
clearInterval(navInterval);

// 나와 있기
navInterval = setInterval(function(){
  $("aside > nav > ul > li").each(function(){
    $(this).removeClass("hover");
  });
  $($("aside > nav > ul > li")[navHoverIdx % $("aside > nav > ul > li").length]).addClass("hover");
  navHoverIdx++;
}, 1500);

//con2
$(document).on('scroll',window, function(){
  var sc=$(this).scrollTop();
  if(sc>300)
  $('.con2_right').css({'top':'-15%','opacity':'1'});
  if(sc<=300)
  $('.con2_right').css({'top':'-25%','opacity':'0'});
  if(sc>230)
  $('.con2_left p').css({'margin-top':'2vh','opacity':'1'});
  if(sc<=230)
  $('.con2_left p').css({'margin-top':'-2vh','opacity':'0'});
  
})
// 컨텐츠3을 슬라이드 시키는 함수
function slideContents3(){
  // 0. #con3_box의 속성 중 미리 정의한 data-idx를 가져와서 정수로 변환
  let idx = parseInt($("#con3_box").attr("data-idx"));

  // 1. 위치, 클래스, 배경이미지 처리
  $('.con3_right').css('left', -13*idx+'vw');
  $('.con3_txt>div').removeClass('on');
  $('.con3_txt>div').eq(idx).addClass('on');
  $('.con3_right>div>img').removeClass('on');
  $('.con3_right>div>img').eq(idx).addClass('on');
  $('.con3').css('background-image','url(/img/con3_'+(idx+1)+'.jpg');

  
  $('.con3_txt>div img').addClass("con3_trsn");
  $('.con3_txt>div img').css({'top':'-22vh','opacity':'0'});
  setTimeout(()=>{
    $('.con3_txt>div img').removeClass("con3_trsn");
    $('.con3_txt>div img').css({'top':'-17vh','opacity':'1'});
  },10);
}
//왼쪽버튼아이콘
$(document).on("click", ".i1", function(){
  // 0. 현재 상태의 #con3_box의 속성 중 미리 정의한 data-idx를 가져와서 정수로 변환
  let idx = parseInt($("#con3_box").attr("data-idx"));
  // 1. 만약 idx가 처음이 아니라면 (0보다 크다면)
  if(idx>0){
    // 1-1. #con3_box의 속성에 현재idx-1 을 설정
    $("#con3_box").attr("data-idx", idx-1);
    // 1-2. 컨텐츠3을 슬라이드 시키는 함수를 호출
    slideContents3();
  }
});
//오른쪽버튼아이콘
$(document).on("click", ".i2", function(){
  // 0. 현재 상태의 #con3_box의 속성 중 미리 정의한 data-idx를 가져와서 정수로 변환
  let idx = parseInt($("#con3_box").attr("data-idx"));
  // 1. 만약 idx가 마지막이 아니라면 (.con3_right의 갯수 - 1보다 작다면)
  if( idx < ( $(".con3_right").children("div").length - 1 ) ){
    // 1-1. #con3_box의 속성에 현재idx+1 을 설정
    $("#con3_box").attr("data-idx", idx+1);
    // 1-2. 컨텐츠3을 슬라이드 시키는 함수를 호출
    slideContents3();
  }
});
//패널 직접 클릭
$(document).on("click", ".con3_right>div", function(){
  // 0. 클릭한 div의 인덱스
  let targetIdx = $(this).index();
  // 1. 클릭한 div의 인덱스가 타겟이 될 것이므로 #con3_box의 속성에 설정
  $("#con3_box").attr("data-idx", targetIdx);
  // 2. 컨텐츠3을 슬라이드 시키는 함수를 호출
  slideContents3();
});
$(document).on('scroll',window, function(){
  var sc=$(this).scrollTop();
  if(sc>1000)
  $('.con3_txt>div img').css({'top':'-17vh','opacity':'1'});
  if(sc<=1000)
  $('.con3_txt>div img').css({'top':'-22vh','opacity':'0'});
  
})

//con5
$(document).on("mouseover", ".company_name>div", function(){
  var e = $(this).index();
  // console.log(e);
  $('.con5 .project>div').eq(e).addClass('on')
  });
$(document).on("mouseout", ".company_name>div", function(){
  $('.project>div').removeClass('on')
});
//up btn설정
$(document).on('click','.upicon', function(){
 $('body,html').stop().animate({scrollTop:0},1000,'swing') 
})
//fadeIn효과
$(document).on('scroll',window, function(){
  var sc=$(this).scrollTop();
  if(sc>900)
  $('.upicon').css({'bottom':'7%','opacity':'1'});
  if(sc<=900)
  $('.upicon').css({'bottom':'9%','opacity':'0'});
  
})
export default {
  mounted(){
    getLastNotice({
      count: 4
      , callback: "noticeMainCallback"
    });
  }
}
</script>